import React from "react";
import BreadCrumbSection from "~sections/Careers/BreadCrumb";
import AboutSection from "~sections/Careers/About";
import ServiceSection from "~sections/Careers/Services";
import JobSection from "~sections/Careers/Job";
import FooterFive from '~sections/agency/FooterFive'

import { PageWrapper } from "~components/Core";
import HeaderButton from '~sections/project/Header'



const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnOneText="Login"
  //     btnTwoText="Get Started For Free"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function Career() {
  return (
    <PageWrapper innerPage={true}>
      <AboutSection />
      {/* <ServiceSection /> */}
      <JobSection />
      <FooterFive />
    </PageWrapper>
  )
}
